import React from "react";

const Header = ({ headerRef }) => {
  return (
    <header
      id="header"
      ref={headerRef}
      className="text-white bg-success w-100 p-2 d-flex"
    >
      <a href="#"><img class="logo" src="/advarch_icon.png" /></a>
      <h1 class="header">Advanced Architecting on AWS 補足資料</h1>
    </header>
  );
};

export default Header;
